export class ApiUrls {
    // public static readonly ErrorUrl: string = '/api/error';
    // public static readonly RootUrl: string = '/api';
    public static readonly JobList: string = '/api/job/list';
    public static readonly JobStopUrl: string = '/api/job/stop';
    public static readonly JobStartUrl: string = '/api/job/start';
    public static readonly JobParametersUrl: string = '/api/job/parameters';
    public static readonly JobTriggerCreateUrl: string = '/api/job/create';
    public static readonly JobTriggerDeleteUrl: string = '/api/job/delete';
    public static readonly PermissionUrl: string = '/api/permission';

    public static readonly ServiceDownloadLogsUrl: string = '/api/service/download-logs';
    public static readonly ServiceDownloadFullLogsUrl: string = '/api/service/download-full-logs';
    public static readonly ServiceDownloadDayLogsUrl: string = '/api/service/download-day-logs';
    // public static readonly ServiceDownloadSapImportsUrl: string = '/api/service/download-sap-imports';
    // public static readonly ServiceSapImportDatesUrl: string = '/api/service/sap-import-dates';
    public static readonly ServiceDownloadMessagesUrl: string = '/api/service/download-messages';
    public static readonly ServiceDownloadDeadlettersUrl: string = '/api/service/download-deadletters';
    public static readonly ServiceDownloadSapExportUrl: string = '/api/service/sapexport/download';
    public static readonly ServiceDatesWithLogsUrl: string = '/api/service/dates';
    public static readonly ServiceMessagesDatesUrl: string = '/api/service/messages-dates';
    public static readonly ServiceQueueDeadlettersUrl: string = '/api/service/input-queue-deadletters';
    
    public static readonly MessageStatisticsUrl: string = '/api/service/message-statistics';

    public static readonly MonitoringUrl: string = '/api/monitoring';

    public static readonly SignalRUserUrl: string = '/api/signalruser/';

    public static readonly SelfTestJobsUrl: string = '/api/selftest/jobs';
    public static readonly SelfTestDatabaseUrl: string = '/api/selftest/database';
    public static readonly SelfTestSignalRUrl: string = '/api/selftest/signalr';
    public static readonly ServiceRestartSchedulerUrl: string = '/api/service/restart-scheduler';
    public static readonly ServiceQueueStatisticsUrl: string = '/api/service/input-queue-statistics';

    public static readonly SalaryDayUrl: string = '/api/Salary/day';
    public static readonly SalaryImportSampleUrl: string = '/api/Salary/download-sample';
    public static readonly RulesValidateDownloadUrl: string = '/api/Rule/validate/download';

    public static readonly ConfigurationUrl: string = '/api/configuration';
}